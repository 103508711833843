$(() => {
    if (!window.CKEDITOR) {
        return;
    }

    const base = '/static/plugins/ckeditor_embed/plugins'

    window.CKEDITOR.plugins.addExternal('embed', base + '/embed/');
    window.CKEDITOR.plugins.addExternal('gallery', base + '/gallery/');
    window.CKEDITOR.plugins.addExternal('embedbase', base + '/embedbase/');
    window.CKEDITOR.plugins.addExternal('widget', base + '/widget/');
    window.CKEDITOR.plugins.addExternal('notificationaggregator', base + '/notificationaggregator/');
    window.CKEDITOR.plugins.addExternal('lineutils', base + '/lineutils/');
    window.CKEDITOR.plugins.addExternal('widgetselection', base + '/widgetselection/');

    window.CKEDITOR.config.extraAllowedContent = 'iframe[*]';

    window.CKEDITOR.on("instanceReady", function (event) {
        const editor = event.editor;
        editor.filter.allow("iframe[*]")
    })
});